import '../css/edittopicform.css';
import tickIcon from '../tick-icon.svg'
import CalendarButton from './calenderbutton'
import editCheckEnabled from '../editcheckenabled.svg';
import unChecked from '../unchecked.svg'
import React, { useState, useEffect } from 'react';
import Loader from './loader'
import ComboBox from 'react-responsive-combo-box'



function EditTopicForm(props) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [topicNameRef, setTopicNameRef] = useState('')
  const [date,setDate] = useState(props.session_data.session_date)
  const [showLoading, setShowLoading] = useState(false)
  const [missed, setMissed] = useState(props.session_data.is_missed)
  const [agentName, setAgentName] = useState(props.session_data.speaker)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    var role = localStorage.getItem('schedule_user_role')
        if ( role == "admin") {
          setIsAdmin(true)
        }
  }, [])

  useEffect(() => {
    if ( topicNameRef !== "") {
      topicNameRef.value = props.session_data.topic_name
    }
  }, [topicNameRef, props.session_data.topic_name])

  function handleEditTopic() {
    setShowLoading(true)
    var topicName = topicNameRef.value
    var speakerName = agentName
    var apikey = localStorage.getItem('schedule_app_apikey')
    var oauth_token = localStorage.getItem('schedule_oauth_token')
    fetch(BASE_URL + 'v1/session_detail/' + props.session_data.detail_id, {
        method : 'PUT',
        headers : {
          'Accept':'application/json',
          'Content-Type':'application/json',
          'x-api-key' : apikey,
          'oauth-token' : oauth_token
      },
      body : JSON.stringify({
            "speaker": speakerName,
            "topic_name": topicName,
            "session_date": date,
            "is_missed": missed
        })
      }).then(res => res.json())
        .then((data) => {
          setShowLoading(false)
          props.saveEdit()
      })
      .catch(console.log)
  }

  function handleDateSelect(date) {
    setDate(date)
  }

  function handleMissedUncheck() {
    setMissed(false)
  }

  function handleMissedCheck() {
    setMissed(true)
  }

  function handleAgentChange(agentName) {
    setAgentName(agentName)
  }


  return (
    <div className="App-session-body-table-edit-form-container">
    <div className="App-session-body-table-edit-form-content" id="App-session-body-table-edit-form-speaker">
          <div className="App-session-body-table-edit-form-text-content-detail">
           {isAdmin ? <ComboBox 
                      options={props.agentNames}
                      inputClassName="combo-agent-box-wrapper"
                      popoverClassName="combo-agent-box-option"
                      placeholder={agentName}
                      editable={false}
                      inputStyles={{'text-align' :'center', 'height' : '35px', 'border' : '1px solid black'}}
                      onSelect={(option) => handleAgentChange(option)}
            /> : props.session_data.speaker}
          </div>
      </div>
      <div className="App-session-body-table-edit-form-content" id="App-session-body-table-edit-form-date">
          <div className="App-session-body-table-edit-form-calender-content-detail">
           {isAdmin ?
            <CalendarButton handleDateSelect={handleDateSelect} date={date}/>
            : props.session_data.session_date
           }
          </div>
      </div>
      <div className="App-session-body-table-edit-form-content" id="App-session-body-table-edit-form-topic-name">
          <div className="App-session-body-table-edit-form-text-content-detail">
            <input type="text" id="topic-name-text-area" ref={el => setTopicNameRef(el)} />
          </div>
      </div>
      <div className="App-session-body-table-edit-form-content" id="App-session-body-table-edit-form-attendance">
          {isAdmin ?
            <div className="App-session-body-table-edit-form-content-detail">
              {missed ? <img alt='' src={editCheckEnabled} onClick={handleMissedUncheck}/> : <img alt='' src={unChecked} onClick={handleMissedCheck} />}
            </div>
          :
            <div className="App-session-body-table-content-detail">
                {props.session_data.is_missed ? <img alt='' src={editCheckEnabled} /> : <img alt='' src={unChecked} />}
            </div>
          }
      </div>
      <div className="App-session-body-table-edit-form-content" id="App-session-body-table-edit-form-tick-icon">
          <div className="App-session-body-table-edit-form-content-detail">
            {showLoading ? <Loader /> : <img alt='' src={tickIcon} onClick={handleEditTopic}/> } 
          </div>
      </div>
    </div>
  );
}

export default EditTopicForm;
