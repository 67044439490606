import logo from '../tekion-logo.png';
import '../css/title.css';

function Title(props) {

  var user_name = localStorage.getItem('schedule_user_name')

  return (
    <div className="App-title-block">
      <div className="App-title-container">
        <div className="App-title-block-title">
          <img src={logo} alt="" class="App-title-block-title-logo"/>
        </div>
        <div className="App-title-user-name-container">
          <div className="App-title-user-name">
            <div className="App-title-user-name-label">
              {user_name}
            </div>
          </div>
          <div className="App-title-logout">
            <div className="App-title-logout-icon" onClick={props.handleLogout}>
              <i class="fa fa-sign-out" id="sign-out" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
