import '../css/sessiontabletopicform.css';
import addIcon from '../add-icon.svg'
import CalendarButton from './calenderbutton'
import React, { useState, useEffect } from 'react';
import Loader from './loader'
import editCheckEnabled from '../editcheckenabled.svg';
import unChecked from '../unchecked.svg'
import ComboBox from 'react-responsive-combo-box'



function SessionTableTopicForm(props) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [topicNameRef, setTopicNameRef] = useState('')
  const [topicName , setTopicName]  = useState(topicNameRef.value)
  const [date,setDate] = useState('Date')
  const [missed, setMissed] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showDisabled, setShowDisabled] = useState(true)
  const [agentName, setAgentName] = useState(props.agentNames[0])


  useEffect(() => {
    if (topicName !== "" && agentName !== "" && date !== "Date" ) {
      setShowDisabled(false)
    } else {
      setShowDisabled(true)
    }
  }, [date, topicName, agentName])


  function handleAddTopic() {
    var topicNameValue = topicNameRef.value
    var speakerName = agentName
    var topicDate = date
    if ( topicNameValue !== "" && speakerName !== "" && topicDate !== "Date") {
      setShowLoading(true)
      var apikey = localStorage.getItem('schedule_app_apikey')
      var oauth_token = localStorage.getItem('schedule_oauth_token')
      fetch(BASE_URL + 'v1/session_detail/session_id/' + props.session_id, {
        method : 'POST',
        headers : {
          'Accept':'application/json',
          'Content-Type':'application/json',
          'x-api-key' : apikey,
          'oauth-token' : oauth_token
        },
        body : JSON.stringify({
              "speaker": speakerName,
              "topic_name": topicNameValue,
              "session_date": date,
              "is_missed": missed
          })
      }).then(res => res.json())
      .then((data) => {
        setShowLoading(false)
        setDate('Date')
        topicNameRef.value = 'Topic Name '
        if ( data['message'] === "Add Session Detail Successful") {
          props.getSessionData(props.session_id)
        }
        
      })
      .catch(console.log)
    } else {

    }
  }

  function handleDateSelect(date) {
    setDate(date)
  }

  function handleMissedUncheck() {
    setMissed(false)
  }

  function handleMissedCheck() {
    setMissed(true)
  }

  function handleAgentChange(agentName) {
    setAgentName(agentName)
  }

  function setTopicNameReference(el) {
    setTopicNameRef(el)
  }

  function handleInputChange() {
    setTopicName(topicNameRef.value)
  }

  return ( 
    <div className="App-session-body-table-form-container">
      <div className="App-session-body-table-form-content" id="App-session-body-table-form-speaker">
          <div className="App-session-body-table-form-speaker-content-detail">
            <ComboBox 
                      options={props.agentNames}
                      inputClassName="combo-agent-box-wrapper"
                      popoverClassName="combo-agent-box-option"
                      placeholder="Select Person"
                      editable={true}
                      style={{'font-weight': 0}}
                      inputStyles={{'text-align' :'left', 'border' : '1px solid grey', 'border-radius': '1px' }}
                      onSelect={(option) => handleAgentChange(option)}
                      enableAutocomplete
                    />
          </div>
      </div>
      <div className="App-session-body-table-form-content" id="App-session-body-table-form-date">
          <div className="App-session-body-table-form-calender-content-detail">
            <CalendarButton handleDateSelect={handleDateSelect} date={date}/>
          </div>
      </div>
      <div className="App-session-body-table-form-content" id="App-session-body-table-form-topic-name">
          <div className="App-session-body-table-form-text-content-detail">
            <input type="text" id="topic-name-text-area" ref={el => setTopicNameReference(el)}  onInput={handleInputChange} placeholder="Topic Name "/>
          </div>
      </div>
      <div className="App-session-body-table-form-content" id="App-session-body-table-form-attendance">
          <div className="App-session-body-table-form-content-detail">
            {missed ? <img alt='' src={editCheckEnabled} onClick={handleMissedUncheck}/> : <img alt='' src={unChecked} onClick={handleMissedCheck} />}
          </div>
      </div>
      <div className="App-session-body-table-form-content" id="App-session-body-table-form-add-icon">
          <div className="App-session-body-table-form-content-detail">
            {showLoading ? <Loader /> : showDisabled ? <img alt='' src={addIcon} class="disabled-add-icon"/> :<img alt='' src={addIcon} onClick={handleAddTopic}/> } 
          </div>
      </div>
    </div>
  );
}

export default SessionTableTopicForm;
