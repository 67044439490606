import './App.css';
import React, { useState, useEffect } from "react";
import Login from './components/login';
import DefaultApp from './components/defaultapp'
import VotingApp from './components/votingapp'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config';


const oktaAuth = new OktaAuth(config.oidc);


function App() {

  const [showLogin, setShowLogin] = useState(true)

  const [loadApp, setLoadApp] = useState(false)

  const BASE_URL = process.env.REACT_APP_BASE_URL;



  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.href = window.location.origin + toRelativeUrl(originalUri || '/', window.location.origin);
    setShowLogin(false)
  };

  console.log(restoreOriginalUri)

  useEffect(() => {
    console.log(oktaAuth)
    var authObject = oktaAuth['authStateManager']['_authState']
    if(oktaAuth.isAuthenticated) {
      if (localStorage.getItem('schedule_app_apikey') == '') {
        console.log(authObject)
        if(authObject) {
          sucessfullLogin(authObject)
          setLoadApp(true)
        }
      }
      setShowLogin(false)
    } else {
      setShowLogin(true)
      setLoadApp(false)
    }

  }, [oktaAuth])

  function handleLogin(apikey, user_name, email) {
    localStorage.setItem('schedule_app_apikey', apikey)
    localStorage.setItem('last_active_time', JSON.stringify(Date.now()))
    localStorage.setItem('schedule_user_name', user_name)
    localStorage.setItem('schedule_user_email', email)
    // setShowLogin(false)
    oktaAuth.signInWithRedirect({ originalUri: '/' , loginHint : 'assas@adsas.com'});
  }

  function handleOauthLogin(email) {
    oktaAuth.signInWithRedirect({originalUri : '/', loginHint : email})
  }

  function sucessfullLogin(authObject) {
    setShowLogin(false)
    console.log(authObject)
    var accesss_token = authObject['accessToken']['accessToken']
    var email = authObject['idToken']['claims']['email']
    var user_name = authObject['idToken']['claims']['name']
    fetch(BASE_URL + 'v1/api_callback', {
        method : 'POST',
        headers : {
          'Accept':'application/json',
          'Content-Type':'application/json'
        },
        body : JSON.stringify({
              "key": accesss_token ,
              "email": email,
              "user_name" : user_name
          })
      }).then(res => res.json())
      .then((data) => {
          if ( data['message']['status'] === "Success") {
            var apiKey = data['message']['api_key']
            var role = data['message']['role']
            localStorage.setItem('schedule_oauth_token', accesss_token)
            localStorage.setItem('schedule_app_apikey', apiKey)
            localStorage.setItem('last_active_time', JSON.stringify(Date.now()))
            localStorage.setItem('schedule_user_name', user_name)
            localStorage.setItem('schedule_user_email', email)
            localStorage.setItem('schedule_user_role', role)
            setLoadApp(true)
          }
      })
      .catch(console.log)
    setShowLogin(false)
  }

  function handleLogout() {
    var apikey = localStorage.getItem('schedule_app_apikey')
    var oauth_token = localStorage.getItem('schedule_oauth_token')
    localStorage.setItem('schedule_app_apikey', '')
    localStorage.setItem('last_active_time', '')
    localStorage.setItem('schedule_user_name', '')
    localStorage.setItem('schedule_user_email', '')
    localStorage.setItem('schedule_user_role', '')
    localStorage.setItem('schedule_oauth_token', '')
    oktaAuth.signOut()
    fetch(BASE_URL + 'v1/logout', {
        method : 'POST',
        headers : {
          'Accept':'application/json',
          'Content-Type':'application/json',
          'x-api-key' : apikey,
          'oauth-token' : oauth_token
        },
      }).then(res => res.json())
      .then((data) => {
      })
      .catch(console.log)
    setShowLogin(true)
  }

  return (
    <div className="App">
      {showLogin 
        ?  <Login handleLogin={handleOauthLogin} />
        : 
          <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
          <BrowserRouter>
            <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/login">
                  <Login handleLogin={handleOauthLogin} />
                </Route>
                <SecureRoute path="/voting/:session_id">
                  <VotingApp handleLogout={handleLogout} sucessfullLogin={sucessfullLogin} loadApp={loadApp} />
                </SecureRoute>
                <SecureRoute path="/">
                  <DefaultApp  handleLogout={handleLogout} sucessfullLogin={sucessfullLogin} loadApp={loadApp}/>
                </SecureRoute>
            </Switch>
          </BrowserRouter>
          </Security>
      }
    </div>
  );
}

export default App;
