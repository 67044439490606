import '../css/sessiontablerow.css';
import checkEnabled from '../check-enabled.svg';
import unChecked from '../unchecked.svg'
import editIcon from '../edit-icon.svg'
import EditTopicForm from './editTopicForm'
import React, { useState } from 'react';


function SessionTableRow(props) {

  const [showEdit, setShowEdit] = useState(false)
  

  function handleEdit() {
    setShowEdit(true)
  }

  function saveEdit() {
    setShowEdit(false)
    props.getSessionData()
  }

  return (
    <div>
    {showEdit ? 
        <EditTopicForm  session_data={props.session_data}  saveEdit={saveEdit} agentNames={props.agentNames} />
      :
        <div className="App-session-body-table-content-container">
          <div className="App-session-body-table-content" id="App-session-body-table-speaker">
              <div className="App-session-body-table-content-detail">
                {props.session_data.speaker}
              </div>
          </div>
          <div className="App-session-body-table-content" id="App-session-body-table-date">
              <div className="App-session-body-table-content-detail">
                {props.session_data.session_date}
              </div>
          </div>
          <div className="App-session-body-table-content" id="App-session-body-table-topic-name">
              <div className="App-session-body-table-content-detail">
                {props.session_data.topic_name}
              </div>
          </div>
          <div className="App-session-body-table-content" id="App-session-body-table-attendance">
              <div className="App-session-body-table-content-detail">
                {props.session_data.is_missed ? <img alt='' src={checkEnabled} /> : <img alt='' src={unChecked} />}
              </div>
          </div>
          <div className="App-session-body-table-content" id="App-session-body-table-edit-icon">
              { !props.isCompleted ?
                  <div className="App-session-body-table-content-detail">
                    <img alt='' src={editIcon}  onClick={handleEdit}/>
                  </div>
                : 
                  <div></div>
              }
          </div>
        </div>
    }
    </div>
  );
}

export default SessionTableRow;
