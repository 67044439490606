import '../css/sessionvotesubmit.css'

function SessionVoteSubmit(props) {
	
	return(
		<div className="App-session-body-vote-submit">
			<div className="App-session-body-vote-submit-button" onClick={props.handleVote}>
				<div className="App-session-body-vote-submit-button-label">
					Submit
				</div>
			</div>
		</div>
	)
}

export default SessionVoteSubmit