import '../css/sessiontablerows.css';
import React from 'react';
import SessionTableRow from './sessiontablerow'


function SessionTableRows(props) {


  function getSessionData() {
    props.getSessionData(props.session_id)
  }

  return (
    <div className="App-session-body-table-contents-container">
    {props.topics.map((row, index) => (
        <SessionTableRow isCompleted={props.isCompleted} session_data={row} key={index} agentNames={props.agentNames} getSessionData={getSessionData} />
      ))}
    </div>
  );
}

export default SessionTableRows;
