import '../css/sessionvotetabletitle.css';

function SessionVoteTableTitle() {


  return (
    <div className="App-session-body-vote-table-title-container">
        <div className="App-session-body-vote-table-title-content" id="App-session-body-vote-table-title-vote-icon">
          <div className="App-session-body-vote-table-title-content-detail">
          </div>
        </div>
        <div className="App-session-body-vote-table-title-content" id="App-session-body-vote-table-title-speaker">
          <div className="App-session-body-vote-table-title-content-detail">
            Speaker
          </div>
        </div>
        <div className="App-session-body-vote-table-title-content" id="App-session-body-vote-table-title-topic-name">
          <div className="App-session-body-vote-table-title-content-detail">
            Topic Name
          </div>
        </div>
        <div className="App-session-body-vote-table-title-content" id="App-session-body-vote-table-title-date">
          <div className="App-session-body-vote-table-title-content-detail">
            Date
          </div>
        </div>
    </div>
  );
}

export default SessionVoteTableTitle;
