import React, { useState, useEffect} from 'react';
import Spinner from './spinner'
import '../css/votingbody.css'
import SessionVoteTable from './sessionvotetable';
import VoteCompleted from './votecompleted'
import NoVoters from './novoters'
import { useOktaAuth } from '@okta/okta-react';


function VotingBody(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

		 const { authState, oktaAuth } = useOktaAuth();


	const [showLoader, setShowLoader] = useState(true)
	const [topics, setTopics] = useState([])
  const [currSessionId, setCurrSessionId] = useState('')
  const [agentNames, setAgentNames] = useState([])
  const [hasVoted, setHasVoted] = useState(false)
  const [votedSpeaker, setVotedSpeaker] = useState("")
  const [showNoVoters, setShowNoVoters] = useState(false)


	useEffect(() => {
		 if(props.loadApp) { 
				if( props.session_id !== null && props.session_id !== "") {
					getSessionData(props.session_id)
					getAgents()
			}
		}
	}, [props.loadApp])

	function getSessionData(session_id) {
    	setShowLoader(true)
    	console.log(BASE_URL)
			var apikey = localStorage.getItem('schedule_app_apikey')
			var email = localStorage.getItem('schedule_user_email')
    	var oauth_token = localStorage.getItem('schedule_oauth_token')
			fetch( BASE_URL + 'v1/vote/session_id/' + session_id + '/details', {
		        method : 'GET',
	          	headers : {
	            	'x-api-key' : apikey,
	            	'oauth-token' : oauth_token
	          	}
		    })
		    .then(res => res.json())
		    .then((data) => {
		    	if ( data['message']['details'].length == 0) {
		    		setShowNoVoters(true)
		    		setShowLoader(false)
		    	} else {
		    		setTopics(data['message']['details'])
		        setCurrSessionId(session_id)
		        setHasVoted(data['message']['is_voted'])
		      	setVotedSpeaker(data['message']['voted_speaker'])
		        setShowLoader(false)
		      }
		    })
	    	.catch(console.log)
    }

    function getAgents() {
   		var apikey = localStorage.getItem('schedule_app_apikey')
   		var oauth_token = localStorage.getItem('schedule_oauth_token')
   		fetch( BASE_URL + 'v1/users/names', {
   				method : 'GET',
   				headers : {
   					'x-api-key' : apikey,
   					'oauth-token' : oauth_token
   				}
   		})
   		.then(res => res.json())
   		.then((data) => {
   				setAgentNames(data['message'])
   		}).catch(console.log)
   }

   function handleVote(selectedSession) {
   	setShowLoader(true)
   	var session_apikey = localStorage.getItem('schedule_app_apikey')
    var user_email = localStorage.getItem('schedule_user_email')
    var oauth_token = localStorage.getItem('schedule_oauth_token')
    setVotedSpeaker(selectedSession.speaker)
    fetch(BASE_URL + 'v1/vote', {
        method : 'POST',
        headers : {
          'Accept':'application/json',
          'Content-Type':'application/json',
          'x-api-key' : session_apikey,
          'oauth-token' : oauth_token
        },
        body : JSON.stringify({
              "session_id" : currSessionId,
              "speaker": selectedSession.speaker,
              "topic_name": selectedSession.topic_name,
              "session_date": selectedSession.session_date,
              "detail_id" : selectedSession.detail_id,
          })
      }).then(res => res.json())
      .then((data) => {
        setHasVoted(true)       
        setShowLoader(false)
      })
      .catch(console.log)
   }

	return(
			<div className="App-voting-body">
				{showLoader ?
					<Spinner /> 
				:
					hasVoted ?
						<VoteCompleted speaker={votedSpeaker} />
					:
						showNoVoters ? 
							<NoVoters /> 
						:
							<div className="App-voting-session-body">
								<div className="App-voting-session-title">
									<div className="App-voting-session-title-label">
										Voting for Sessions
									</div>
								</div>
								<div className="App-voting-session-table">
									<SessionVoteTable handleVote={handleVote} session_id={currSessionId} getSessionData={getSessionData} topics={topics} agentNames={agentNames} />
								</div>
							</div>
				}
			</div>
		)

}

export default VotingBody