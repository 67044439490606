import '../css/sessiontable.css';
import SessionTableTitle from './sessiontabletitle'
import SessionTableRows from './sessiontablerows'
import SessionTableTopicForm from './sessiontabletopicform'
import SessionCompleted from './sessioncompleted'
import React, { useState, useEffect } from 'react';


function SessionTable(props) {

  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    var role = localStorage.getItem('schedule_user_role')
    if ( role == "admin") {
      setIsAdmin(true)
    }
  }, [])

  return (
    <div className="App-session-body-table-container">
      {props.isCompleted ? <SessionCompleted session_id={props.session_id} /> : <div></div>}
      <SessionTableTitle />
      <SessionTableRows isCompleted={props.isCompleted} topics={props.topics} agentNames={props.agentNames} session_id={props.session_id} getSessionData={props.getSessionData} />
      {!props.isCompleted && isAdmin ? <SessionTableTopicForm session_id={props.session_id} getSessionData={props.getSessionData} agentNames={props.agentNames} /> : <div></div> }
    </div>
  );
}

export default SessionTable;
