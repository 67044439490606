import '../css/sessiontabletitle.css';

function SessionTableTitle() {


  return (
    <div className="App-session-body-table-title-container">
        <div className="App-session-body-table-title-content" id="App-session-body-table-title-speaker">
          <div className="App-session-body-table-title-content-detail">
            Speaker
          </div>
        </div>
        <div className="App-session-body-table-title-content" id="App-session-body-table-title-date">
          <div className="App-session-body-table-title-content-detail">
            Date
          </div>
        </div>
        <div className="App-session-body-table-title-content" id="App-session-body-table-title-topic-name">
          <div className="App-session-body-table-title-content-detail">
            Topic Name
          </div>
        </div>
        <div className="App-session-body-table-title-content" id="App-session-body-table-title-attendance">
          <div className="App-session-body-table-title-content-detail">
            Missed
          </div>
        </div>
        <div className="App-session-body-table-title-content" id="App-session-body-table-title-edit-icon">
          <div className="App-session-body-table-title-content-detail">
          </div>
        </div>
    </div>
  );
}

export default SessionTableTitle;
