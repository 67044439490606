import React, { useState, useEffect } from 'react';
import '../css/addsession.css'

function AddSession(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const [sessionNameRef, setSessionNameRef] = useState('')
	const [showError, setShowError] = useState(false)
	const [showLoader, setShowLoader] = useState(false)

	function handleClose() {
		props.handleClose()
	}

	function handleCreateSession() {
		setShowError(false)
		setShowLoader(true)
		var apikey = localStorage.getItem('schedule_app_apikey')
    	var user_name = localStorage.getItem('schedule_user_name')
    	var oauth_token = localStorage.getItem('schedule_oauth_token')
    	if ( sessionNameRef.value !== "" ) {
	    	fetch( BASE_URL + 'v2/session' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "name": sessionNameRef.value,
	            'user' : user_name
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    		if ( data['message'] === "Session Name already present") {
	    			setShowError(true)
	    			setShowLoader(false)
	    		} else {
	    			setShowLoader(false)
	    			props.getSessions()
	    			props.getActiveSession()
	    			props.handleClose()
	    		}
	    	})
	    	.catch(console.log)
    	}
	}

	return(
			<div className="App-add-session-modal"> 
				<div className="App-add-session-modal-content">
					<div className="App-add-session-modal-header">
						<div className="App-add-session-modal-header-title">
							Create New Session
						</div>
						<div className="close" onClick={handleClose}>&times;</div>
					</div>
					<div className="App-add-session-modal-body">
						<div className="App-add-session-modal-label-content">
							<div className="App-add-session-modal-label-content-text">
								Session Name <span className="App-add-session-modal-label-content-text-mandatory">*</span>
							</div>
						</div>
						<div className="App-add-session-modal-text-area-container">
						{ showError ? 
								<div className="App-add-session-modal-text-area-error">
									<input type="text" id="App-add-session-modal-text-area-text-error" placeholder="Type here" ref={el => setSessionNameRef(el) }/>
									<p className="App-add-session-modal-text-area-error-label">Session Name already present</p>
								</div>
							:
								<div className="App-add-session-modal-text-area">
									<input type="text" id="App-add-session-modal-text-area-text" placeholder="Type here" ref={el => setSessionNameRef(el) }/>
								</div>
						}
						</div>
					</div>
					<div className="App-add-session-modal-footer">
						<div className="App-add-session-modal-footer-button-container">
							<div className="App-add-session-footer-done"  onClick={handleClose}>
								<div className="App-add-session-footer-done-label">Cancel</div>
							</div>
							<div className="App-add-session-footer-save"  onClick={handleCreateSession}>
							{
								showLoader ? 
									<div className="App-add-session-footer-save-label">
										<i class="fa fa-spinner fa-pulse"></i>
									</div>
								:
									<div className="App-add-session-footer-save-label">Save</div>
							}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
}

export default AddSession