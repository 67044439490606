import Title from './title'
import VotingBody from './votingbody'
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useOktaAuth } from '@okta/okta-react';


function VotingApp(props) {

	let params = useParams();
	const sessionId =  params.session_id

	 const { authState, oktaAuth } = useOktaAuth();

      console.log(authState)
      console.log(oktaAuth)

      useEffect(() => {
            if(authState.isAuthenticated) {
                props.sucessfullLogin(authState)
            }
        }, 
        [authState, oktaAuth])


	return(
			<div className="App-body-container">
				<Title handleLogout={props.handleLogout}/>
				<VotingBody session_id={sessionId} loadApp={props.loadApp} />
			</div>
		)
}

export default VotingApp