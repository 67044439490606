import React from 'react';
import '../css/rolltovote.css'
import copyIcon from '../copy-icon.svg'


function RollToVote(props) {

	function handleCopy() {
		navigator.clipboard.writeText(props.votingURL)
	}

	function handleClose() {
		console.log("clicked close")
		props.handleClose()
	}

	return(
			<div id="myModal" class="App-session-body-role-to-vote">
				<div class="App-session-body-role-to-vote-modal-content">
					<div class="App-session-body-role-to-vote-modal-header">
						<div class="App-session-body-role-to-vote-modal-body-header-title">
							Voting URL
						</div>
						<div class="close" onClick={handleClose}>&times;</div>
					</div>
					<div class="App-session-body-role-to-vote-modal-body">
						<div class="App-session-body-role-to-vote-modal-body-label-content">
							<p>Share this URL with people. They will be able to vote for an individual using this URL.</p>
						</div>
						<div class="App-session-body-role-to-vote-modal-body-url-content">
							<div class="App-session-body-role-to-vote-modal-body-url-content-url">
								{props.votingURL}
							</div>
							<div class="App-session-body-role-to-vote-modal-body-url-content-copy">
								<img alt='' src={copyIcon} onClick={handleCopy}/>
							</div>
						</div>
					</div>
					<div class="App-session-body-role-to-vote-modal-footer">
						<div class="App-session-body-role-to-vote-footer-done"  onClick={handleClose}>
							<div class="App-session-body-role-to-vote-footer-done-label">Done</div>
						</div>
					</div>
				</div>
			</div>
		)

}

export default RollToVote