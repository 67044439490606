import React, {useState, useEffect} from 'react'

function VoteBox(props) {

	const [hasResult, setHasResult] = useState(false)

	useEffect(() => {
		if ( props.result != null) {
			setHasResult(true)
		}
	}, [props.result])

	
	return(
		<div className="App-session-vote-box">
			{hasResult ? 
					<div className="App-session-vote-box-details">
						<div className="App-session-vote-rank">
							#  {props.result.rank}
						</div>
						<div className="App-session-vote-box-result-details">
							<div className="App-session-vote-box-result-speaker-name">
								{props.result.speaker}
							</div>
							<div className="App-session-vote-box-result-speaker-votes">
								{props.result.count}  votes
							</div>
						</div>
					</div>
					: <div></div>
			}
		</div>
	)
}

export default VoteBox