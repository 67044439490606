import '../css/sessionvotetable.css';
import SessionVoteTableTitle from './sessionvotetabletitle'
import SessionVoteTableRows from './sessionvotetablerows'
import SessionVoteSubmit from './sessionvotesubmit'
import React, {useState} from 'react';

function SessionVoteTable(props) {


  const [selectedSession, setSelectedSession] = useState({})

  function handleSelectedSession(session) {
    setSelectedSession(session)
  }

  function handleVote() {
    console.log(selectedSession)
    props.handleVote(selectedSession)
  }


  return (
    <div className="App-session-body-vote-table-container">    
      <SessionVoteTableTitle />
      <SessionVoteTableRows setSelectedSession={handleSelectedSession} topics={props.topics} agentNames={props.agentNames} session_id={props.session_id} getSessionData={props.getSessionData} />
      <SessionVoteSubmit handleVote={handleVote}/>
    </div>
  );
}

export default SessionVoteTable;
