import completedImage from '../voting-completed-image.png'
import '../css/votingcompleted.css'

function VoteCompleted(props) {
	
	return(
		<div className="App-session-body-vote-completed">
			<div className="App-session-body-vote-completed-content-container">
				<div className="App-session-body-vote-completed-content-image">
					<img src={completedImage} alt='' className="App-session-body-vote-completed-image"/>
				</div>
				<div className="App-session-body-vote-completed-content-thank-you">
					Thank you for your submission
				</div>
				<div className="App-session-body-vote-completed-content-voted-speaker">
					You voted for {props.speaker}
				</div>
			</div>
		</div>
	)
}

export default VoteCompleted