import '../css/sessionvotetablerows.css';
import React, {useState, useEffect} from 'react';
import SessionVoteTableRow from './sessionvotetablerow'


function SessionVoteTableRows(props) {

  const [selectedIndex, setSelectedIndex] = useState('')

  useEffect(() => {
    setSelectedIndex(0)
    props.setSelectedSession(props.topics[0])
  }, [])

  function getSessionData() {
    props.getSessionData(props.session_id)
  }

  function handleVoteSelection(index) {
    setSelectedIndex(index)
    props.setSelectedSession(props.topics[index])
  }

  return (
    <div className="App-session-body-vote-table-contents-container">
    {props.topics.map((row, index) => (
        <SessionVoteTableRow selectedIndex={selectedIndex} handleVoteSelection={handleVoteSelection} session_data={row} key={index} index={index} agentNames={props.agentNames} getSessionData={getSessionData} />
      ))}
    </div>
  );
}

export default SessionVoteTableRows;
