import React from 'react';
import Calendar from 'react-calendar';
import '../css/calender.css';


function Calender(props) {

  const date = new Date();

  function onChange(value) {
  	var output = changeDateFormat(value)
  	console.log(output)
  	props.handleDateSelection(output)
  }

  function changeDateFormat(value) {
  	let d = new Date(value);
	let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
	let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	var output = `${da} ${mo}, ${ye}`
	return output
  }

  return (
      <Calendar
        onChange={onChange}
        value={date}
        calendarType={"US"}
      />
  );
}

export default Calender
