import React, {useEffect} from 'react'
import '../css/voteresulttable.css'

function VoteResultTable(props) {

	useEffect(() => {
		console.log(props.results)
	}, [])

	return (
		<div className="App-session-vote-result-table-container">
			<div className="App-session-vote-result-table-title-container">
				<div className="App-session-vote-result-table-title-content" id="App-session-vote-result-table-title-rank">
          			<div className="App-session-vote-result-table-title-content-detail">
            			Rank
          			</div>
        		</div>
				<div className="App-session-vote-result-table-title-content" id="App-session-vote-result-table-title-speaker">
          			<div className="App-session-vote-result-table-title-content-detail">
            			Name
          			</div>
        		</div>
        		<div className="App-session-vote-result-table-title-content" id="App-session-vote-result-table-title-topic">
          			<div className="App-session-vote-result-table-title-content-detail">
            			Topic
          			</div>
        		</div>
        		<div className="App-session-vote-result-table-title-content" id="App-session-vote-result-table-title-count">
          			<div className="App-session-vote-result-table-title-content-detail">
            			Count
          			</div>
        		</div>
			</div>
			 <div className="App-session-vote-result-table-contents-container">
			 	{props.results.map((row, index) => (
			 		<div className="App-session-vote-result-table-content-container" key={index}>
			 			<div className="App-session-vote-result-table-content" id="App-session-vote-result-table-rank">
              				<div className="App-session-vote-result-table-content-detail">
              					{row.rank}
              				</div>
         			 	</div>
          				<div className="App-session-vote-result-table-content" id="App-session-vote-result-table-speaker">
              				<div className="App-session-vote-result-table-content-detail">
              					{row.speaker}
              				</div>
         			 	</div>
         			 	<div className="App-session-vote-result-table-content" id="App-session-vote-result-table-topic">
              				<div className="App-session-vote-result-table-content-detail">
              					{row.topic_name}
              				</div>
         			 	</div>
         			 	<div className="App-session-vote-result-table-content" id="App-session-vote-result-table-count">
              				<div className="App-session-vote-result-table-content-detail">
              					{row.count}
              				</div>
         			 	</div>
         			 </div>
			 	))}
			 </div>
		</div>
	)
}

export default VoteResultTable