import Title from './title'
import Body from './body'
import '../App.css';
import React, { useState, useEffect } from "react";
import { useOktaAuth } from '@okta/okta-react';



function DefaultApp(props) {

      const { authState, oktaAuth } = useOktaAuth();

      console.log(authState)
      console.log(oktaAuth)

      useEffect(() => {
            if(authState.isAuthenticated) {
                  props.sucessfullLogin(authState)
            }
        }, 
        [])


    return(
            <div className="App-body-container">
                  <Title handleLogout={props.handleLogout}/>
                  <Body loadApp={props.loadApp} />
                </div>
        )
}

export default DefaultApp