import React from 'react'
import '../css/voteresult.css'
import VoteResultTable from './voteresulttable'

function VoteResult(props) {

	function handleClose(){
		props.handleClose()
	}
	
	return( 
		<div className="App-session-vote-result-container">
			<div className="App-session-vote-result-modal-content">
				<div className="App-session-vote-result-header">
					<div className="App-session-vote-result-header-label">
						Results
					</div>
					<div className="close" onClick={handleClose}>
						&times;
					</div>
				</div>
				<div className="App-session-vote-result-table">
					<VoteResultTable results={props.results} />
				</div>
				<div className="App-session-vote-result-footer">
					<div class="App-session-vote-result-footer-done"  onClick={handleClose}>
						<div class="App-session-vote-result-footer-done-label">Done</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default VoteResult