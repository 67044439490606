import '../css/flashingspinner.css'

function Spinner() {

	return(
		<div class="snippet" data-title=".dot-elastic">
          <div class="stage">
            <div class="dot-flashing"></div>
          </div>
		</div>
	)
}

export default Spinner