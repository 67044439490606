import '../css/body.css'
import SessionTable from './sessiontable'
import Spinner from './spinner'
import RollToVote from './rolltovote'
import React, { useState, useEffect } from 'react';
import AddSession from './addsession'
import ComboBox from 'react-responsive-combo-box'
import 'react-responsive-combo-box/dist/index.css'
import addIcon from '../add-icon-white.svg'
import chevronDown from '../chevron-down.svg'



function Body(props) {

		const BASE_URL = process.env.REACT_APP_BASE_URL;
		var host = window.location.protocol + "//" + window.location.host;

  	const [topics, setTopics] = useState([])
  	const [currSession, setCurrSession] = useState({})
  	const [prevSessId, setPrevSessId] = useState(null)
  	const [nextSessId, setNextSessId] = useState(null)
  	const [showLoader, setShowLoader] = useState(true)
  	const [agentNames, setAgentNames] = useState([])
  	const [isCompleted, setIsCompleted] = useState(false)
  	const [sessionNameRef, setSessionNameRef] = useState('')
  	const [enableVote, setEnableVote]  = useState(true)
  	const [votingURL, setVotingURL] = useState('')
  	const [showVoteDetail, setShowVoteDetail]  = useState(false)
  	const [sessionNames, setSessionNames] = useState([])
  	const [sessionNameMap, setSessionNameMap] = useState({})
  	const [selectedSessionName, setSelectedSessionName] = useState('')
  	const [selectedSessionId, setSelectedSessionId] = useState('')
		const [showAddSession, setShowAddSession] = useState(false)
		const [showRollToVote, setShowRollToVote] = useState(false)
		const [isAdmin, setIsAdmin] = useState(false)


  	useEffect(() => {
  		if(props.loadApp) {
  			getSessions()
      	getActiveSession()
      	getAgents()
      	var role = localStorage.getItem('schedule_user_role')
      	if ( role == "admin") {
      		setIsAdmin(true)
      	}
    	}
    }, [props.loadApp]);

    useEffect(() => {
    	console.log(selectedSessionId)
    }, [selectedSessionId])


   	function getSessions() {
   		var apikey = localStorage.getItem('schedule_app_apikey')
   		var oauth_token = localStorage.getItem('schedule_oauth_token')
   		fetch( BASE_URL + 'v1/session', {
   				method : 'GET',
   				headers : {
   					'x-api-key' : apikey,
   					'oauth-token' : oauth_token
   				}
   		})
   		.then(res => res.json())
   		.then((data) => {
   				var sessions = data['message']
   				var session_names = []
   				var session_name_map = {}
   				sessions.map((session) => {
   					session_names.push(session.session_name)
   					session_name_map[session.session_name] = session.session_id
   				})
   				setSelectedSessionName(session_names[0])
   				setSelectedSessionId(session_name_map[session_names[0]])
   				setSessionNames(session_names)
   				setSessionNameMap(session_name_map)
   		})
   		.catch(console.log)
   	}


   function getActiveSession() {
		var apikey = localStorage.getItem('schedule_app_apikey')
		var schedule_user_name = localStorage.getItem('schedule_user_name')
		var oauth_token = localStorage.getItem('schedule_oauth_token')
      fetch( BASE_URL + 'v2/session_detail/active_session', {
          method : 'GET',
          headers : {
            'x-api-key' : apikey,
            'oauth-token' : oauth_token
          }
      })
      .then(res => res.json())
      .then((data) => {
      		setNextSessId(data['message']['next_session_id'])
          setTopics(data['message']['details'])
          setCurrSession(data['message']['session'])
		setIsCompleted(data['message']['session']['is_completed'])
		setShowLoader(false)
		if (data['message']['session']['created_by'] === schedule_user_name){
			  setShowRollToVote(true)
		  }
      })
      .catch(console.log)
   }

   function handleRolltoVote() {
   		setShowVoteDetail(true)
   		setVotingURL(host + '/voting/' + currSession.session_id)
	   	if(enableVote) {
	   			var oauth_token = localStorage.getItem('schedule_oauth_token')
		   		var apikey = localStorage.getItem('schedule_app_apikey')
		   		fetch( BASE_URL + 'v1/session/' + currSession.session_id + '/complete', {
		   			method : 'PUT',
		   			headers : {
		   				'x-api-key' : apikey,
		   				'oauth-token' : oauth_token
		   			}
		   		})
		   		.then(res => res.json())
		   		.then((data) => {
		   			setEnableVote(false)
		   		})
	   	}
   }


 	function handleVoteClose() {
 		setShowVoteDetail(false)		
 	}

   function getAgents() {
   		var apikey = localStorage.getItem('schedule_app_apikey')
   		var oauth_token = localStorage.getItem('schedule_oauth_token')
   		fetch( BASE_URL + 'v1/users/names', {
   				method : 'GET',
   				headers : {
   					'x-api-key' : apikey,
   					'oauth-token' : oauth_token
   				}
   		})
   		.then(res => res.json())
   		.then((data) => {
   				setAgentNames(data['message'])
   		}).catch(console.log)
   }

    function handleShowCreateSession() {
    	setShowAddSession(true)
    }

    function handleCloseCreateSession() {
    	setShowAddSession(false)
    }

    function handleCreateSession() {
    	var apikey = localStorage.getItem('schedule_app_apikey')
    	var user_name = localStorage.getItem('schedule_user_name')
    	var oauth_token = localStorage.getItem('schedule_oauth_token')
    	if ( sessionNameRef.value !== "" ) {
	    	setShowLoader(true)
	    	fetch( BASE_URL + 'v1/session' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        		'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "name": sessionNameRef.value,
	            'user' : user_name
	        })
	    	})
	    	.then(res => res.json())
	    	.then((data) => {
	    			getActiveSession()
	    	})
	    	.catch(console.log)
    	}
    }


    function getSessionData(session_id) {
		setShowLoader(true)
		var schedule_user_name = localStorage.getItem('schedule_user_name')
    	console.log(BASE_URL)
			var apikey = localStorage.getItem('schedule_app_apikey')
			var oauth_token = localStorage.getItem('schedule_oauth_token')
			fetch( BASE_URL + 'v1/session_detail/session_id/' + session_id , {
		        method : 'GET',
	          	headers : {
	            	'x-api-key' : apikey,
	            	'oauth-token' : oauth_token
	          	}
		    })
		    .then(res => res.json())
		    .then((data) => {
		    			setNextSessId(data['message']['next_session_id'])
		          setTopics(data['message']['details'])
		          setCurrSession(data['message']['session'])
		          setIsCompleted(data['message']['session']['is_completed'])
		          setPrevSessId(data['message']['prev_session_id'])
				  setShowLoader(false)
				  if (data['message']['session']['created_by'] === schedule_user_name){
					  console.log('Username', schedule_user_name)
					  console.log('API Username', data['message']['session']['created_by'])
					setShowRollToVote(true)
				}
		    })
	    	.catch(console.log)
	    	console.log(currSession)
    }


    function handleOptionChange(option) {
		setShowRollToVote(false)
    	setSelectedSessionName(option)
    	setSelectedSessionId(sessionNameMap[option])
		getSessionData(sessionNameMap[option])
    }



	return (
		<div className="App-body">
			{showLoader ? 
				<Spinner />
				:
				<div className="App-sesion-table-container">
					<div className="App-session-table-title-container">
						<div className="App-session-table-title">
							
							<div className="App-session-table-title-name">
									<div className="App-session-table-title-dropdown">
										<ComboBox 
											options={sessionNames}
											inputClassName="combo-box-wrapper"
											placeholder={selectedSessionName}
											editable={true}
											renderRightElement={()=> <img src={chevronDown} alt='down' id="combo-down-arrow" /> }
											inputStyles={{'text-align' :'center', 'text-overflow':'ellipsis'}}
											onSelect={(option) => handleOptionChange(option)}
											enableAutocomplete
										/>
									</div>
							</div>
							<div className="App-session-table-title-dot">
								<div className="App-session-table-title-status-content">
									<div className="App-session-table-title-status-dot-container">
										{!isCompleted ? <div className="App-session-table-title-status-dot"></div>
										 : <div className="App-session-table-title-status-completed-dot"></div>
										}
									</div>
									<div className="App-session-table-title-status-container">
										<div className="App-session-table-title-status">
											{isCompleted ? "Completed" : "Active" }
										</div>
									</div>
								</div>
							</div>

							{isAdmin && 
								<div className="App-session-table-create-session">
									<div className="App-session-table-create-session-button" onClick={handleShowCreateSession}>
										<img src={addIcon} alt="add-button" className="App-session-table-create-session-plus" />
									</div>
								</div>
							}					
						</div>
						{showAddSession && <AddSession handleClose={handleCloseCreateSession} getSessions={getSessions} getActiveSession={getActiveSession} />}
						<div className="App-session-table-title-actions">
							{ showRollToVote ? <div className="App-session-table-title-button-holder">
								<div className="App-session-table-title-button-roll-to-vote" >
									<div onClick={handleRolltoVote}>
										Roll to Vote
									</div>
									{showVoteDetail ? <RollToVote votingURL={votingURL} handleClose={handleVoteClose}/>
										: <div></div>
									}
								</div>
							</div>
							: <div></div>
}
						</div>
					</div>
					<div className="App-session-table">
						<SessionTable isCompleted={isCompleted} session_id={currSession.session_id} getSessionData={getSessionData} topics={topics} agentNames={agentNames} />
					</div>
				</div>
			}
			<div className="App-session-pagination-container">
			</div>
		</div>
	)
}

export default Body