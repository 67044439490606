import '../css/calender.css';
import React, { useState } from 'react';
import calenderIcon from '../calender-icon.svg'
import Calender from './calender'



function CalenderButton(props) {

	const [date, setDate] = useState(props.date)
	const [showCal, setShowCal] = useState(false)

	function handleCalenderClick() {
		if (showCal === false) {
			setShowCal(true)
		} else {
			setShowCal(false)
		}
	}

	function handleDateSelection(output) {
		setDate(output)
		setShowCal(false)
		props.handleDateSelect(output)
	}

	return(
		<div className="calender-container">
			<div className="calender-date-label-container">
				<div className="calender-date-label">
					{date}
				</div>
			</div>
			<div className="calender-date-icon" onClick={handleCalenderClick}>
				<img alt='' src={calenderIcon} />
			</div>
			{showCal ? <Calender handleDateSelection={handleDateSelection} /> : <div></div>}
		</div>
	)
}

export default CalenderButton