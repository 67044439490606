import '../css/sessionvotetablerow.css';
import React, { useState, useEffect } from 'react';
import radioButtonEnabled from '../radio-button-enabled.svg'
import radioButton from '../radio-button.svg'


function SessionVoteTableRow(props) {

  const [showEnabled, setShowEnabled] = useState(false)

  useEffect(() => {
    if(props.selectedIndex === props.index) {
        setShowEnabled(true)
    } else {
        setShowEnabled(false)
    }
  }, [props.selectedIndex])

  function handleVoteSelection() {
    props.handleVoteSelection(props.index)
  }

  return (
    <div>
        <div className="App-session-body-vote-table-content-container">
          <div className="App-session-body-vote-table-content" id="App-session-body-vote-table-vote">
              <div className="App-session-body-vote-table-content-detail">
                {showEnabled ? <img alt='' src={radioButtonEnabled} /> : <img alt='' src={radioButton} onClick={handleVoteSelection} /> }
              </div>
          </div>
          <div className="App-session-body-vote-table-content" id="App-session-body-vote-table-speaker">
              <div className="App-session-body-vote-table-content-detail">
                {props.session_data.speaker}
              </div>
          </div>
          <div className="App-session-body-vote-table-content" id="App-session-body-vote-table-topic-name">
              <div className="App-session-body-vote-table-content-detail">
                {props.session_data.topic_name}
              </div>
          </div>
          <div className="App-session-body-vote-table-content" id="App-session-body-vote-table-date">
              <div className="App-session-body-vote-table-content-detail">
                {props.session_data.session_date}
              </div>
          </div>
        </div>
    </div>
  );
}

export default SessionVoteTableRow;
