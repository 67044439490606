import React, { useState, useEffect } from 'react';
import VoteBox from './votebox'
import VoteResult from './voteresult'
import '../css/sessioncompleted.css';
import chevronRight from '../chevron-right.svg'

function SessionCompleted(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const [results, setResults] = useState([])
	const [hasResults, setHasResults] = useState(false)
	const [showResult, setShowResult] = useState(false)

	useEffect(() => {
		var apikey = localStorage.getItem('schedule_app_apikey')
		var oauth_token = localStorage.getItem('schedule_oauth_token')
      	fetch( BASE_URL + 'v1/vote/session_id/' + props.session_id, {
          	method : 'GET',
          	headers : {
            	'x-api-key' : apikey,
            	'oauth-token' : oauth_token
          	}
      	})
      	.then(res => res.json())
      	.then((data) => {
      		var results = data['message']
      		console.log(results)
      		if ( results.length > 0 ) {
      			setHasResults(true)
      			setResults(data['message'])
      		}
      	})
      	.catch(console.log)
	}, [])

	function handleShowResult() {
		setShowResult(true)
	}

	function handleResultClose() {
		setShowResult(false)
	}


	return(
			<div className="App-session-completed-container">
				{hasResults ?
					<div className="App-session-completed-inner-container"> 
						<VoteBox rank="1" result={results[0]} />
						<VoteBox rank="2" result={results[1]} />
						<VoteBox rank="3" result={results[2]} />
						<div className="App-session-completed-view-result-container" onClick={handleShowResult}>
							<div className="App-session-completed-view-result-label">
								View Result
							</div>
							<div className="App-session-completed-view-result-chevron">
								<img src={chevronRight} alt='' />
							</div>
						</div>
						{showResult ? <VoteResult results={results} handleClose={handleResultClose}/> : <div></div>}
					</div>
					:
					<div className="App-session-completed-inner-container">
						<div className="App-session-vote-box">
							<div className="App-session-vote-box-details">
								<div className="App-session-vote-box-result-wait">
									Voting in Progress
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
}

export default SessionCompleted